import React from "react";
import Script from "next/script";

const AppScripts = () => {
  const prod = process.env.NODE_ENV === "production";

  if (!prod) return <></>;

  return (
    <Script
      strategy="afterInteractive"
      type="text/javascript"
      id="hs-script-loader"
      async
      defer
      src="//js-na1.hs-scripts.com/23363761.js"
    />
  );
};

export default AppScripts;
